<style lang="scss">
.table-header {
  border: 1px solid #ebeef5;
  border-bottom: none;
  height: 50px;
}
.refresh {
  background-color: #f5f5f5;
  width: 50px;
  &:hover .icon_refresh {
    color: rgba(0, 0, 0, 0.6);
  }
}
.icon_refresh {
  color: #999;
  line-height: 50px;
}
.operation {
  white-space: nowrap;
}
.mb20 {
  margin-bottom: 60px;
}
.el-tag {
  margin-left: 10px;
}
.el-card {
  height: 100% !important;
}
.box-card {
  font-size: 12px;
}
.no-wrap {
  white-space: nowrap;
}
</style>
<template>
  <div class="account height_100 flex_tb">
    <!-- 條件刪選 -->
    <collapse @seach="state.seach" v-if="state.tableData.length" />
    <!-- 内容 -->
    <el-row style="height: 100%; overflow: auto" class="flex_wrap">
      <el-empty
        description="暫無數據"
        v-if="!state.tableData.length"
        style="margin-left: 50%; transfrom: translateX(-50%)"
      ></el-empty>
      <el-col
        :span="7"
        v-for="item in state.tableData"
        :key="item.id"
        style="margin-right: 30px; margin-bottom: 30px"
      >
        <el-card class="box-card">
          <template #header>
            <div class="card-header" style="font-size: 14px">
              <span>訂單號：{{ item.order_no }}</span>
              <span
                style="float: right; cursor: pointer; color: #8cc5ff"
                @click="look_detail(item.order_no)"
                >查看詳情</span
              >
            </div>
          </template>
          <el-row style="height: 376px; overflow: auto">
            <el-col :span="12" class="flex_c_m mb20">
              <span style="margin-right: 10px" class="no-wrap">收穫名：</span>
              <span>{{ item.name }}</span>
            </el-col>
            <el-col :span="12" class="flex_c_m mb20">
              <span style="margin-right: 10px" class="no-wrap">電話：</span>
              <span>{{ item.phone }}</span>
            </el-col>
            <el-col :span="12" class="flex_c_m mb20">
              <span style="margin-right: 10px" class="no-wrap">地址：</span>
              <span>{{ item.address }}</span>
            </el-col>
            <el-col :span="12" class="flex_c_m mb20">
              <span style="margin-right: 10px" class="no-wrap"
                >訂單縂金額：</span
              >
              <span>{{ item.money }}</span>
            </el-col>
            <el-col :span="12" class="flex_c_m mb20">
              <span style="margin-right: 10px" class="no-wrap">取貨法式：</span>
              <span>{{ item.receiving_method == 0 ? "直送" : "自取" }}</span>
            </el-col>
            <el-col :span="12" class="flex_c_m mb20">
              <span style="margin-right: 10px" class="no-wrap">支付方式：</span>
              <span>{{ item.payment_mode == 1 ? "PalPay" : "錢包" }}</span>
            </el-col>
            <el-col :span="12" class="flex_c_m mb20">
              <span style="margin-right: 10px" class="no-wrap">創建時間：</span>
              <span>{{ item.created_at }}</span>
            </el-col>
            <el-col :span="12" class="flex_c_m mb20">
              <span style="margin-right: 10px" class="no-wrap">使用優惠：</span>
              <span>{{
                item.coupon_type == 1
                  ? `立減${item.discount}`
                  : item.coupon_type == 2
                  ? `滿${item.lowest}減${item.discount}`
                  : item.coupon_type == 3
                  ? `立折${item.discount}折`
                  : item.coupon_type == 4
                  ? `滿${item.lowest}打${item.discount}折`
                  : "無"
              }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 分頁 -->
    <div class="flex_m_r pain pad20" v-if="state.tableData.length">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="state.user.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(state.count)"
      >
      </el-pagination>
    </div>
    <!-- 彈窗 -->
    <pop-up
      :dialogVisible="state.dialogVisible"
      :userId="'' + state.userId"
      @toggleDialogVisible="toggleDialogVisible"
      @reloadData="reloadData"
    />
    <!-- 提示框 -->
    <el-dialog title="提示" v-model="state.dialog" width="400px">
      <span>確定刪除嗎？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialog = false">取 消</el-button>
          <el-button type="primary" @click="dels_confirm">確 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import Collapse from "./components/collapse";
import PopUp from "./components/pop-up";

export default {
  components: {
    Collapse,
    PopUp,
  },
  setup() {
    const req_table_data = () => {
      $http.getOrderManagementList(state.user).then((res) => {
        console.log(res);
        if (res.status !== 200) return;
        state.tableData = res.data.data;
        state.count = res.data.count;
      });
    };

    const state = reactive({
      tableData: [], // 表格数据
      user: {
        // 请求参数
        limit: 10,
        page: 1,
        order_no: "",
        time: "",
      },
      count: "", // 總條數
      seach(data) {
        // 查询里面的数据
        state.user.order_no = data.seatch_name;
        state.user.time = data.time;
        req_table_data();
      },
      dialogVisible: false, // 弹窗开关
      dialog: false, // 提示框开关
      delAccountId: [], // 批量刪除的id
      delUserId: [], // 单个删除的id
      userId: "", // 用户id
    });

    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $message, $router } = proxy;

    // init
    req_table_data();

    // 分頁組件改變页数
    const handleCurrentChange = (val) => {
      state.user.page = val;
      req_table_data();
    };

    // 分頁組件改變每頁顯示条数
    const handleSizeChange = (val) => {
      state.user.limit = val;
      req_table_data();
    };

    // 刷新
    const refresh = () => {
      req_table_data();
    };

    // 弹出层的显示开关
    const toggleDialogVisible = () => {
      state.dialogVisible = false;
    };

    // 弹出层点击确定触发
    const reloadData = (data) => {
      console.log(data);
      req_table_data();
    };

    // 选中的id数组
    const selection_change = (selection) => {
      state.delAccountId = selection.map((item) => {
        item = item.id;
        return item;
      });
    };

    // 选中的id数组
    const del_accout = () => {
      state.delUserId = [];
      state.dialog = true;
    };

    // 确定弹出层点击确定
    const dels_confirm = async () => {
      const res = await $http.delOrderManagementList({
        id: state.delUserId.length === 0 ? state.delAccountId : state.delUserId,
      });
      if (res.status !== 200) return;
      req_table_data();
      $message.success("刪除成功");
      state.dialog = false;
    };

    // 刪除用戶
    const deleteInfo = (data) => {
      state.delUserId = [data.id];
      state.dialog = true;
    };

    // 查看数据列表
    const lookInfo = (data) => {
      console.log(data);
      // state.dialogVisible = true;
    };

    // 查看訂單號
    const look_detail = (data) => {
      $router.push({
        path: "/ServiceManagement",
        query: {
          order_id: data,
        },
      });
    };

    return {
      state,
      handleCurrentChange,
      handleSizeChange,
      refresh,
      toggleDialogVisible,
      reloadData,
      selection_change,
      del_accout,
      dels_confirm,
      deleteInfo,
      lookInfo,
      look_detail,
    };
  },
};
</script>
